<template>
  <div>
    <v-dialog
      v-model="dialogShow"
      :max-width="formDialogOptions.width"
      :style="{ zIndex: formDialogOptions.zIndex }"
      @keydown.esc.prevent="closeForm"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      :hide-overlay="$vuetify.breakpoint.smAndDown"
    >
      <v-card>
        <v-form v-model="valid" ref="form">
          <v-toolbar dark color="brown" class="color-gradient-1" dense>
            <v-btn icon dark @click="closeForm">
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>

            <span class="ml-2 mr-2 font-weight-medium grey--text"> | </span>
            <span v-if="formMode === 'EDIT_FORM'">EDIT </span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'en'"> NEW</span>
            <span class="font-weight-light ml-1 mr-1">ITEM</span>
            <span v-if="formMode === 'NEW_FORM' && lang === 'id'"> BARU</span>

            <span
              class="ml-2 mr-2 font-weight-medium grey--text"
              v-show="isItemModified"
            >
              |
            </span>
            <v-chip
              class="ma-2"
              color="warning"
              outlined
              x-small
              v-show="isItemModified"
            >
              <v-icon left> mdi-pencil </v-icon>
              modfied
            </v-chip>

            <v-spacer></v-spacer>
            <v-toolbar-items class="mx-0">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    dark
                    v-bind="attrs"
                    v-on="on"
                    rounded
                    icon
                    class="px-5"
                    x-small
                  >
                    <v-icon color="black" v-if="false">mdi-translate</v-icon>
                    <v-avatar size="18" class="elevation-1">
                      <v-img :src="getFlagImage(lang)"></v-img>
                    </v-avatar>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item
                    v-for="(item, index) in menu_language"
                    :key="index"
                    dense
                  >
                    <v-list-item-title>
                      <v-avatar size="22" class="elevation-1">
                        <v-img :src="getFlagImage(item.id)"></v-img>
                      </v-avatar>
                      <v-btn
                        small
                        plain
                        elevation="0"
                        @click="languageChange(item.id)"
                      >
                        {{ item.description }}
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar-items>

            <v-toolbar-items>
              <v-btn
                dark
                text
                @click="save"
                :disabled="!valid || isItemModified === false"
                class="hidden-md-and-up"
              >
                Simpan
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <v-card-title>
            <v-container class="pa-4 text-center">
              <v-row class="fill-height" align="center" justify="center">
                <template>
                  <v-col cols="12" md="4">
                    <v-hover v-slot="{ hover }">
                      <v-card
                        :elevation="hover ? 10 : 1"
                        :class="[
                          { 'on-hover': hover },
                          hover
                            ? 'card-hover-opacity'
                            : 'card-not-hover-opacity',
                        ]"
                      >
                        <v-img
                          :lazy-src="lookupImageUrlLazy(itemModified)"
                          :src="lookupImageUrl(itemModified)"
                          height="225px"
                        >
                          <v-card-title class="text-h6 white--text fill-height">
                            <v-row
                              class="fill-height flex-column"
                              justify="space-between"
                            >
                              <v-spacer />
                              <div class="align-self-center">
                                <v-btn
                                  :class="{ 'show-btns': hover }"
                                  :color="transparent"
                                  icon
                                  large
                                  dark
                                  outlined
                                  @click="showDialogUpload"
                                >
                                  <v-icon
                                    :class="{ 'show-btns': hover }"
                                    :color="transparent"
                                    large
                                  >
                                    mdi-upload
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-card-title>
                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </template>
              </v-row>
            </v-container>
          </v-card-title>

          <v-card-subtitle>
            <v-flex class="d-flex">
              <v-flex>
                <v-hover v-slot="{ hover }">
                  <v-card
                    :elevation="hover ? 10 : 1"
                    :class="[
                      { 'on-hover': hover },
                      hover ? 'card-hover-opacity' : 'card-not-hover-opacity',
                    ]"
                    height="150px"
                    width="150px"
                  >
                    <v-img
                      :src="lookupImageMarkerUrl(itemModified.markerImage1)"
                      height="150px"
                      width="150px"
                    >
                      <v-card-title class="text-h6 white--text fill-height">
                        <v-row
                          class="fill-height flex-column"
                          justify="space-between"
                        >
                          <v-spacer />
                          <div class="align-self-center">
                            <v-btn
                              :class="{ 'show-btns': hover }"
                              :color="transparent"
                              icon
                              large
                              dark
                              outlined
                              @click="showDialogUploadMarker1"
                            >
                              <v-icon
                                :class="{ 'show-btns': hover }"
                                :color="transparent"
                                large
                              >
                                mdi-upload
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-row>
                      </v-card-title>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-flex>
          </v-card-subtitle>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="4" md="4">
                  <v-text-field
                    v-model="itemModified.kode1"
                    label="Kode"
                    :rules="rulesLenght"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-if="lang === 'id'"
                    v-model="itemModified.description"
                    :rules="rulesNotEmtpy"
                    label="Nama Jenis Komoditi"
                  ></v-text-field>
                  <v-text-field
                    v-if="lang === 'en'"
                    v-model="itemModified.descriptionEn"
                    :rules="rulesNotEmtpy"
                    label="Komodity Type Name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="2" md="2">
                  <v-switch
                    v-model="itemModified.statusActive"
                    :label="itemModified.statusActive ? 'Aktif' : 'Non-Aktif'"
                    x-small
                    class="pa-3"
                  ></v-switch>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="itemModified.fsectorTypeBean"
                    :rules="rulesNotEmtpy"
                    :items="itemsFSectorType"
                    item-value="id"
                    :item-text="lang == 'id' ? 'description' : 'descriptionEn'"
                    auto-select-first
                    dense
                    small-chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    label="SECTOR"
                    hint="Sector"
                    persistent-hint
                    single-line
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3">
                  <v-autocomplete
                    v-model="itemModified.fsatuanBean"
                    :items="itemsFSatuan"
                    item-value="id"
                    item-text="description"
                    dense
                    chips
                    small-chips
                    :label="lang === 'id' ? 'Satuan' : 'Unit'"
                    hide-details
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-autocomplete
                    v-model="itemModified.fdivisionBean"
                    :items="itemsFDivision"
                    :rules="rulesNotEmtpy"
                    item-value="id"
                    item-text="description"
                    auto-select-first
                    dense
                    chips
                    deletable-chips
                    color="blue-grey lighten-2"
                    :label="
                      lang === 'id'
                        ? 'Pemilik Data (Kab/Kota)'
                        : 'Data Owner (District/City)'
                    "
                    :hint="
                      lang === 'id'
                        ? `Pemilik Data (Kab/Kota) : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                        : `Data Owner (District/City) : ${
                            lookupFDivision(itemModified.fdivisionBean).kode1
                          }`
                    "
                    persistent-hint
                    single-line
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-chip
              class="ml-4"
              color="error"
              outlined
              close
              small
              v-show="formDialogOptions.errorMessage"
            >
              {{ formDialogOptions.errorMessage }}
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="translateForm"
              class="hidden-sm-and-down mr-4"
              :disabled="
                lang !== 'en' &&
                (itemModified.id !== 0 || itemModified.id !== null)
              "
            >
              {{ lang === "id" ? "Terjemahkan" : "Translate" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="closeForm"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Batal" : "Cancel" }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              outlined
              text
              @click="save"
              :disabled="!valid || isItemModified === false"
              class="hidden-sm-and-down"
            >
              {{ lang === "id" ? "Simpan" : "Save" }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>

      <CloseConfirmDialog
        ref="refCloseConfirmDialog"
        @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></CloseConfirmDialog>

      <UploadImageDialog
        ref="refUploadDialog"
        @eventUploadSuccess="completeUploadSuccess"
      >
      </UploadImageDialog>

      <UploadImageDialogMarker1
        ref="refUploadDialogMerker1"
        @eventUploadSuccess="completeUploadSuccessMarker1"
      >
      </UploadImageDialogMarker1>

      <v-dialog width="100px" v-model="dialogLoading">
        <v-card class="px-4 py-7">
          <v-row justify="center">
            <v-col cols="12" align="center">
              <v-progress-circular
                :size="40"
                :width="5"
                v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar">
        {{ snackBarMesage }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>
  </div>
</template>

<script>
import FKomoditiTypeService from "../../../services/apiservices/f-komoditi-type-service";

import CloseConfirmDialog from "../../utils/CloseConfirmDialog";
import FormMode from "../../../models/form-mode";
import FKomoditiType from "../../../models/f-komoditi-type";
import FileService from "../../../services/apiservices/file-service";
import UploadImageDialog from "../../utils/UploadImageDialog";
import UploadImageDialogMarker1 from "../../utils/UploadImageOriDialog";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";

export default {
  components: {
    CloseConfirmDialog,
    UploadImageDialog,
    UploadImageDialogMarker1,
  },
  props: {
    formMode: String,
    itemsFSectorType: [],
    itemsFSatuan: [],
  },
  data() {
    return {
      menu_language: [
        { id: "en", description: "English", icon: "flag_en.jpg" },
        { id: "id", description: "Indonesia", icon: "flag_id.jpg" },
      ],

      title: "Komoditi Type",
      snackBarMesage: "",
      snackbar: false,

      imageOverlay: false,

      dialogLoading: false,
      loading: false,

      dialogShow: false,
      formDialogOptions: {
        title1: "",
        title2: "",
        message1: "",
        message2: "",
        errorMessage: "",
        color: "grey lighten-3",
        width: 800,
        zIndex: 200,
        noconfirm: false,
      },

      selectedItemIndex: -1,
      itemDefault: "",
      itemModified: new FKomoditiType(0, "", ""),
      itemPassed: "",

      selectFDivision: { id: 0, kode1: "", description: "" },
      itemsFDivision: [],

      valid: false,
      rulesLenght: [
        (v) => !!v || " Tidak Boleh Kosong",
        (v) => v.length <= 255 || "Maksimal 255 Karakter",
        (v) => v.length > 1 || "Harus lebih besar dari 1 karakter",
      ],
      rulesNotEmtpy: [(v) => !!v || "Tidak boleh kosong"],

      transparent: "rgba(255, 255, 255, 0)",
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault);
      const modifiedItem = JSON.stringify(this.itemModified);
      return defaultItem !== modifiedItem;
    },
    lang: {
      get() {
        return this.$store.state.langModule.lang;
      },
      set(val) {
        this.$store.dispatch("langModule/setLang", val);
      },
    },
  },
  watch: {
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          // this.fetchFSectorType()
        }
      },
    },
  },

  methods: {
    getFlagImage(fileName) {
      const lang = fileName;
      if (lang === undefined) {
        return require("@/assets/i18n/flag_id.jpg");
      } else {
        const langJson = this.menu_language.filter(
          (item) => item.id === `${lang}`
        );
        return require("@/assets/i18n/" + langJson[0].icon);
      }
    },
    languageChange(value) {
      this.lang = value;
      this.$i18n.locale = this.lang;
      // this.initializeEditMode(this.itemPassed)
    },

    translateForm() {
      if (this.lang === "en") {
        this.snackBarMesage = "";
        this.dialogLoading = true;
        this.loading = true;
        LangTranslatorService.getPostChatGptTranslateIdToEn(
          this.itemModified.description
        ).then((response) => {
          this.itemModified.descriptionEn = response.data.translation
            .replaceAll("+", " ")
            .replaceAll("=", " ")
            .trim();

          this.dialogLoading = false;
          this.loading = false;
          this.snackbar = true;
          if (this.lang === "id") {
            this.snackBarMesage = "Data berhasil diterjemahkan";
          }
          if (this.lang === "en") {
            this.snackBarMesage = "Data successfully translated";
          }
        });
      }
    },
    showDialogUpload() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fdivisionBean !== 0 &&
        this.itemModified.fsectorTypeBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialog.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi dan Jenis Sektor harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccess: function (val) {
      // console.log(val)
      //Hapus Dulu yang lama
      console.log(this.itemModified.avatarImage);

      if (
        this.itemModified.avatarImage !== undefined &&
        this.itemModified.avatarImage !== ""
      ) {
        FileService.deleteImage(this.itemModified.avatarImage).then(
          (response) => {
            console.log(response.data);
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialog.closeDialog();
        this.itemModified.avatarImage = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialogUploadMarker1() {
      if (
        this.itemModified.kode1 !== "" &&
        this.itemModified.description !== "" &&
        this.itemModified.fdivisionBean !== 0 &&
        this.itemModified.fsectorTypeBean !== 0
      ) {
        if (this.itemModified.id === 0) {
          // this.$emit('eventSaveItemWithoutClose', false)
          this.saveCreateOnly();
        }
        this.$refs.refUploadDialogMerker1.showDialog();
      } else {
        this.snackBarMesage =
          "Kode, Deskripsi, Divisi dan Jenis Sektor harus diisi dahulu";
        this.snackbar = true;
      }
    },

    completeUploadSuccessMarker1: function (val) {
      console.log(val);

      if (
        this.itemModified.markerImage1 !== undefined &&
        this.itemModified.markerImage1 !== ""
      ) {
        FileService.deleteFile(this.itemModified.markerImage1).then(
          (response) => {
            console.log(response.data);
          },
          (error) => {
            console.log(error.response);
          }
        );
      }

      if (val.fileName !== "") {
        this.$refs.refUploadDialogMerker1.closeDialog();
        this.itemModified.markerImage1 = val.fileName;
        this.saveUpdateOnly();
      }
    },

    showDialog(selectedIndex, item, itemsFDivision) {
      // console.log("show UserDialog " + item.id)
      // console.log(this.formMode)
      this.dialogShow = !this.dialogShow;
      if (selectedIndex > -1) {
        this.selectedIndex = selectedIndex;
        this.initializeEditMode(item);
      } else {
        (this.itemDefault = new FKomoditiType(0, "", "")),
          (this.itemModified = new FKomoditiType(0, "", "")),
          (this.selectedIndex = -1);
      }

      this.itemsFDivision = itemsFDivision;
    },
    setDialogState(value) {
      this.dialogShow = value;
    },
    save() {
      if (this.isItemModified === false) {
        //Close aja
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
        return;
      }
      if (this.$refs.form.validate()) {
        if (this.formMode === FormMode.EDIT_FORM) {
          FKomoditiTypeService.updateFKomoditiType(this.itemModified).then(
            () => {
              // console.log(response.data)
              this.$emit("eventFromFormDialogEdit", this.itemModified);
            },
            (error) => {
              // console.log(error);
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        } else {
          FKomoditiTypeService.createFKomoditiType(this.itemModified).then(
            (response) => {
              this.$emit("eventFromFormDialogNew", response.data);
            },
            (error) => {
              this.formDialogOptions.errorMessage = error.response.data.message;
            }
          );
        }
      }
    },
    saveCreateOnly() {
      FKomoditiTypeService.createFKomoditiType(this.itemModified).then(
        (response) => {
          /**
           * dipaksa Save dan Update Dahulu
           */
          // this.initializeEditMode(response.data)
          this.$emit("update:formMode", FormMode.EDIT_FORM);
          this.itemModified.id = response.data.id;
        },
        (error) => {
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    saveUpdateOnly() {
      FKomoditiTypeService.updateFKomoditiType(this.itemModified).then(
        () => {},
        (error) => {
          // console.log(error);
          this.formDialogOptions.errorMessage = error.response.data.message;
        }
      );
    },
    closeForm() {
      if (!this.isItemModified) {
        this.dialogShow = false;
        this.$emit("eventFromFormDialog1", this.itemModified);
      } else {
        this.$refs.refCloseConfirmDialog.showDialog(
          " Sudah terdapat modifikasi data",
          "Tetap tutup dan reset perubahan?"
        );
      }
    },
    passingEventFromCloseConfirm(value) {
      if (value === "OKE") this.dialogShow = false;
    },
    initializeEditMode(item) {
      this.formDialogOptions.errorMessage = "";
      FKomoditiTypeService.getFKomoditiTypeById(item.id).then(
        (response) => {
          // console.log(response.data)
          this.itemDefault = Object.assign({}, response.data);
          this.itemModified = response.data;
        },
        (error) => {
          console.log(error);
        }
      );
    },
    lookupFDivision(fdivisionBean) {
      const str = this.itemsFDivision.filter((x) => x.id === fdivisionBean);
      if (str.length > 0) {
        return `${str[0].description} (${str[0].kode1})`;
      } else {
        return "-";
      }
    },
    lookupImageUrl(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_medium(item.avatarImage);
      }
    },
    lookupImageUrlLazy(item) {
      if (item.avatarImage === undefined || item.avatarImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        return FileService.image_url_verylow(item.avatarImage);
      }
    },

    lookupImageMarkerUrl(markerImage) {
      if (markerImage === undefined || markerImage === "") {
        return "./assets/images/no_image_available.jpeg";
      } else {
        // console.log(FileService.file_url(markerImage))
        return FileService.image_url_ori(markerImage);
      }
    },
  },
};
</script>

<style scoped>
.card-hover-opacity {
  opacity: 0.4;
}
.card-not-hover-opacity {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.show-btns {
  color: blue !important;
  opacity: 1;
}
</style>