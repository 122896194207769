<template>
  <div class="align-self-center">
    <v-dialog
        v-model="dialogShow"
        :max-width="formDialogOptions.width"
        :style="{ zIndex: formDialogOptions.zIndex }"
        @keydown.esc.prevent="closeDialog"
        transition="dialog-top-transition"
    >
      <v-card>
        <v-toolbar
            class="grey lighten-3"
            elevation="0"
            dense
        >
          <v-btn
              icon
              @click="closeDialog"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>Upload Gambar Ext</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="justify-center">
          <v-row justify="center" align="center">
              <v-col cols="12" md="12" sm="6">
                  <v-text-field
                  label="Title or Description"
                  v-model="description"
                  small
                  ></v-text-field>
              </v-col>
          </v-row>

          <v-row justify="center" align="center">
            <v-col cols="10">
              <v-file-input
                  v-model="currentImage"
                  show-size
                  counter
                  label="Pilih Gambar"
                  accept="image/*"
                  @change="selectImage"
              ></v-file-input>
            </v-col>

            <v-col cols="2" class="pl-2">
              <v-btn color="success" small @click="startUpload" :disabled="!currentImage || parentId===0">
                Upload
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <div v-if="progress">
            <div>
              <v-progress-linear
                v-model="progress"
                color="light-blue"
                height="25"
                reactive
              >
                <strong>{{ progress }} %</strong>
              </v-progress-linear>
            </div>
          </div>

          <v-row v-if="previewImage" justify="center">
              <v-img class="ma-2" :src="previewImage" alt="" max-width="300px" max-height="300px"></v-img>
          </v-row>

        </v-card-text>

      </v-card>

      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>

      <close-confirm-dialog
          ref="refCloseConfirmDialog"
          @eventFromCloseConfirm="passingEventFromCloseConfirm"
      ></close-confirm-dialog>

    </v-dialog>

    <v-dialog
        v-model="dialogProgres"
        hide-overlay
        persistent
        width="300"
    >
      <v-card
          color="primary"
          dark
      >
        <v-card-text>
          Please Wait...
          <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import UploadService from "@/services/apiservices/file-upload-service";
import CloseConfirmDialog from "@/components/utils/CloseConfirmDialog";

export default {
  name: "upload-image",
  props:{
    parentId: Number
  },
  components:{
    CloseConfirmDialog
  },
  data() {
    return {
      dialogProgres: false,
      dialogShow: false,
      formDialogOptions: {
        title1: '',
        title2: '',
        message1: '',
        message2: '',
        errorMessage: '',
        color: "grey lighten-3",
        width:800,
        zIndex: 200,
        noconfirm: false,
      },

      // itemDefault: '',
      // itemModified: '',

      currentImage: undefined,
      previewImage: undefined,

      progress: 0,
      message: "",
      description: '',

      imageInfos: [],
    };
  },
  computed: {
    isItemModified() {
      const defaultItem = JSON.stringify(this.itemDefault)
      const modifiedItem = JSON.stringify(this.itemModified)
      return defaultItem !== modifiedItem
    }
  },

  methods: {
    selectImage(image) {
      this.currentImage = image;
      this.previewImage = URL.createObjectURL(this.currentImage);
      this.progress = 0;
      this.message = "";
    },

    startUpload() {
      if (!this.currentImage) {
        this.message = "Please select an Image!";
        return;
      }


      this.progress = 0;
      this.dialogProgres = true

      UploadService.uploadDocument(this.currentImage, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then(response => {
            this.message = response.data.message
            /**
             * Mendapat response nama file
             */
            const messageToParent = {
                fileName: this.message,
                description: this.description,
            }
            this.$emit('eventUploadSuccess', messageToParent)

          this.dialogProgres = false
        })
        .catch((err) => {
          this.progress = 0;
          this.message = "Could not upload the image! " + err;
          this.currentImage = undefined;

          this.dialogProgres =false

        });
    },

    showDialog(){
      this.dialogShow = true
      this.currentImage = undefined
      this.previewImage = undefined
      this.progress =0
      this.message = ""

    },
    closeDialog(){
      if (! this.isItemModified){
        this.dialogShow = false
        this.$emit('eventFromFormDialog1', this.itemModified)
      }else {
        this.$refs.refCloseConfirmDialog.showDialog(" Sudah terdapat modifikasi data", "Tetap tutup dan reset perubahan?")
      }
    },
    passingEventFromCloseConfirm(value){
      if (value==='OKE') this.dialogShow = false
    },
  },
  mounted() {
    // UploadService.getFiles().then((response) => {
    //   this.imageInfos = response.data;
    // });

  },
};
</script>

<style scoped>
  .preview {
    max-width: 200px;
  }
</style>
