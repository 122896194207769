import { render, staticRenderFns } from "./KomoditiTypeView.vue?vue&type=template&id=c1c04f40&scoped=true"
import script from "./KomoditiTypeView.vue?vue&type=script&lang=js"
export * from "./KomoditiTypeView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1c04f40",
  null
  
)

export default component.exports