import axios from 'axios';
import authHeader from "../../services/auth-header";
import ConstApiUrls from "../../services/const-api-urls";

const API_URL = ConstApiUrls.API_SERVICE_URL

class FSectorTypeService {
    getApiUrl() {
        return API_URL
    }
    getAllFSectorTypePublic(){
        return axios.get(API_URL + `getAllFSectorTypePublic`, { headers: authHeader() });
    }
    getAllFSectorType(){
        return axios.get(API_URL + `getAllFSectorType`, { headers: authHeader() });
    }
    getAllFSectorTypeByOrgLevel(){
        return axios.get(API_URL + `getAllFSectorTypeByOrgLevel`, { headers: authHeader() });
    }
    getAllFSectorTypeContaining(page, pageSize, sortBy, order, search){
        // return axios.get(API_URL + `getAllFSectorType`, { headers: authHeaderMultipart() });
        return axios.get(API_URL + `getAllFSectorTypeContaining?page=${page}&pageSize=${pageSize}&sortBy=${sortBy}&order=${order}&search=${search}`, { headers: authHeader() });
    }
    getFSectorTypeById(id){
        return axios.get(API_URL + `getFSectorTypeById/${id}`, { headers: authHeader() });
    }
    updateFSectorType(item){
        return axios.put(API_URL + `updateFSectorType/${item.id}`, item, {headers: authHeader()})
    }
    createFSectorType(item){
        return axios.post(API_URL + `createFSectorType`, item, {headers: authHeader()})
    }
    deleteFSectorType(id){
        return axios.delete(API_URL + `deleteFSectorType/${id}`, {
            headers: authHeader()
        });
    }
    deleteAllFSectorType(itemIds){
        // let user = JSON.parse(localStorage.getItem('user'));
        // return axios.delete(API_URL + `deleteAllFSectorType`, {
        //     headers:  authHeaderMultipart(),
        //     data: {
        //         message: 'delete  success'
        //     }
        // });
        return axios.delete(API_URL + `deleteAllFSectorType`, {
            headers:  authHeader(),
            data: itemIds
        });


    }
}
export default new FSectorTypeService()